<template>
    <v-container fluid class="px-6" style="height: calc(100vh - 120px);overflow-y:auto;">

      <v-card elevation="2" >

        <v-card-title class="px-6 card-header white--text">
            <v-icon class="mr-3 white--text">mdi-database-import-outline</v-icon> <h3>Calibration History Summary</h3>
        </v-card-title>

          <v-form class="main-form-card">
          <v-card class="mt-8 pa-5 mx-4" outlined>
            <v-container>
              <v-row>

                <v-col cols="12" sm="6">
                  <v-autocomplete
                  v-model="stationSelected"
                  v-on:change="selectValueStation"
                  :items="itemStation"
                  label="Station"
                  prepend-icon="mdi-store-24-hour"
                  multiple
                  chips
                  required
                  outlined 
                  dense
                  >
                    <template v-slot:prepend-item>
                          <v-list-item
                          ripple
                          @mousedown.prevent
                          @click="toggle"
                          >
                          <v-list-item-action>
                              <v-icon :color="stationSelected.length > 0 ? 'indigo darken-4' : ''">
                              {{ icon }}
                              </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                              <v-list-item-title>
                              Select All
                              </v-list-item-title>
                          </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                      </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  
                    <v-select
                    prepend-icon="mdi-calendar"
                    v-on:change="selectValueYearDate"
                    :items="dropdownYearDate"
                    label="Year"
                    class="input-date-year"
                    outlined 
                    dense
                    >
                    </v-select>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                    <v-select
                    prepend-icon="mdi-calendar"
                    v-on:change="selectValueMonthDate"
                    :items="dropdownMonthDate"
                    label="Month"
                    class="input-date-year"
                    outlined 
                    dense
                    >
                    </v-select>
                </v-col>

              </v-row>

              <v-row justify="center">
                <v-btn
                :disabled="disableBtnGenerate"
                class="button white--text"
                @click="generateTable"
                >
                  GENERATE
                </v-btn>
              </v-row>

              <v-row>

                <v-col>

                  <v-data-table
                  :headers="theadSummaryCalibration"
                  :items="tbodySummaryCalibration"
                  :header-props="{ sortIcon: null }"
                  class="elevation-1 my-5 headerDtSarawak"
                  :items-per-page="10"
                  no-select-on-click
                  fixed-header
                  :loading="loadingTable"
                  loading-text="Data is loading.."
                  >

                    <template v-slot:[`item.datetime`]="{ item }">
                      <span v-html="item.datetime"></span>
                    </template>

                    <template v-slot:[`item.fromDatetime`]="{ item }">
                      <span v-html="item.fromDatetime"></span>
                    </template>

                    <template v-slot:[`item.toDate`]="{ item }">
                      <span v-html="item.toDate"></span>
                    </template>

                  </v-data-table>

                </v-col>

              </v-row>

            </v-container>
          </v-card>
          </v-form>

          <br><br>
      </v-card>
        
    </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data: () => ({

    itemStation: [],
    // stationSelected: null,
    stationSelected: [],
    startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

    dataHistory: [],

    // Engine
    modalStartCalendar: false,
    modalEndCalendar: false,

    yearDate: null,
    monthDate: null,
    dropdownYearDate: [],
    dropdownMonthDate: [ "All Month", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    listMonthDate: [
      { id:"all", text:"All Month" },
      { id:"01", text:"January" },
      { id:"02", text:"February" },
      { id:"03", text:"March" },
      { id:"04", text:"April" },
      { id:"05", text:"May" },
      { id:"06", text:"June" },
      { id:"07", text:"July" },
      { id:"08", text:"August" },
      { id:"09", text:"September" },
      { id:"10", text:"October" },
      { id:"11", text:"November" },
      { id:"12", text:"December" }
    ],

    disableBtnGenerate: true,

    theadSummaryCalibration: [
      {text: "STATION ID", value: "stationId", sortable: true},
      {text: "Date & Time", value: "datetime", sortable: true},
      {text: "PARAMETER", value: "parameter", sortable: true},
      {text: "Start Date & Time", value: "fromDatetime", sortable: true},
      {text: "End Date & Time", value: "toDate", sortable: true},
      {text: "CREATED BY", value: "flagBy", sortable: true},
      {text: "UNFLAG BY", value: "unFlagBy", sortable: true},

    ],
    tbodySummaryCalibration: [],

    loadingTable: false,

  }),
  methods:{

    load(){
      this.loadStationList();
      this.getApiDateYear();
    },

    loadStationList(){
      
      this.itemStation = [];

      axios.get(this.globalUrl+'bakaj/stations', {
          headers: {
              'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
      })
      .then((response) => {

          for (let i = 0; i < response.data.length; i++) {
            this.itemStation.push(response.data[i].stationId + " - " + response.data[i].location);
          }
          
      })
      .catch(error => {
          console.log(error);
      })

    },

    getApiDateYear(){
      axios.get(this.globalUrl+'bakaj/years', {
          headers: {
              'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
      })
      .then((response) => {
          // this.stationDetails = response.data;
          console.log(response.data);
          for (let i = 0; i < response.data.length; i++) {
            this.dropdownYearDate.push(response.data[i]);
          }

      })
      .catch(error => {
          console.log(error);
      })
    },

    loadDataHistoryRecord(station,month,year){

      axios.get(this.globalUrl+'maint/maintcal?month='+month+'&year='+year, {
          headers: {
              'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
      })
      .then((response) => {

        let data = response.data;
        this.tbodySummaryCalibration = [];
        this.dataHistory = [];

        for(let i in data){
          if (data[i]["datetime"] != null) {
            data[i]["datetime"] = this.convertDateFormat(data[i]["datetime"].split("T")[0]) + "<br>" + this.convert12Hours(data[i]["datetime"].split("T")[1]);
          }
          if (data[i]["fromDatetime"] != null) {
            data[i]["fromDatetime"] = this.convertDateFormat(data[i]["fromDatetime"].split("T")[0]) + "<br>" + this.convert12Hours(data[i]["fromDatetime"].split("T")[1]);
          }
          if (data[i]["toDate"] != null) {
            data[i]["toDate"] = this.convertDateFormat(data[i]["toDate"].split("T")[0]) + "<br>" + this.convert12Hours(data[i]["toDate"].split("T")[1]);
          }
        }

        for(let i in data){
          
          if (data[i].toDate != null && data[i].unFlagBy != null) {
            if (data[i].flag === "C") {

              for(let x in this.stationSelected){
                if (data[i].stationId == this.stationSelected[x].split(" - ")[0]) {
                  this.dataHistory.push(data[i]);
                }
              }
            
            }
            
          }
        }

        this.tbodySummaryCalibration = this.dataHistory;
        this.loadingTable = false;

          
      })
      .catch(error => {
          console.log(error);
          this.loadingTable = false;
      })
    },

    selectValueStation: function(e){
      // this.stationSelected = e.split(" - ")[0];
    },

    toggle () {
        this.$nextTick(() => {
          if (this.checkedAllStations) {
            this.stationSelected = []
          } 
          else {
            this.stationSelected = this.itemStation.slice()
          }
        })
    },

    selectValueYearDate: function(e){

      this.disableBtnGenerate = true;

      this.yearDate = e;

      if (this.stationSelected.length != 0 && this.yearDate != null && this.monthDate != null) {
        this.disableBtnGenerate = false;
      }
    },

    selectValueMonthDate: function(e){

      this.disableBtnGenerate = true;

      for(let i in this.listMonthDate){
        if (this.listMonthDate[i].text == e) {
          this.monthDate = this.listMonthDate[i].id;
        }
      }


      if (this.stationSelected.length != 0 && this.yearDate != null && this.monthDate != null) {
        this.disableBtnGenerate = false;
      }
    },

    generateTable(){
      if (this.stationSelected.length > 0) {
        this.loadingTable = true;
        this.tbodySummaryCalibration = [];
        // this.loadDataHistoryRecord(this.stationSelected,this.startDate,this.endDate);
        this.loadDataHistoryRecord(this.stationSelected,this.monthDate,this.yearDate);
      }
    },

    checkDisabledBtnStatus(){
      this.disableBtnGenerate = true;
      
      if (this.stationSelected.length != 0 && this.yearDate != null && this.monthDate != null) {
        this.disableBtnGenerate = false;
      }
    },

    convert12Hours(time) {
        var H = +time.substr(0, 2);
        var h = H % 12 || 12;
        var ampm = (H < 12 || H === 24) ? "AM" : "PM";
        time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
        return time;
    },
    
    convertDateFormat(date){
        var dd = date.split("-")[2];
        var mm = date.split("-")[1];
        var yyyy = date.split("-")[0];
        date = dd + "/" + mm + "/" + yyyy;
        return date;
    },
      
  },

  mounted(){
    this.load();
  },

  computed: {
    checkedAllStations () {
        this.checkDisabledBtnStatus();
        return this.stationSelected.length === this.itemStation.length
    },
    checkedSomeStations () {
        this.checkDisabledBtnStatus();
        return this.stationSelected.length > 0 && !this.checkedAllStations
    },
    icon () {
        if (this.checkedAllStations) return 'mdi-close-box'
        if (this.checkedSomeStations) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
    },
  }
}
</script>

<style lang="scss">
@import '~scss/main';

.v-subheader {
  height: 30px;
}
</style>